import { Button } from '@/components/Button/Button';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

export const Container = styled.div``;

export const PartySizeButton = styled((props: ComponentProps<typeof Button>) => (
  <Button variant="secondary" {...props} />
))`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.s};
  padding: ${({ theme }) => `${theme.space.m} ${theme.space.halfXxl}`};
`;

export const MoreButton = styled(PartySizeButton)`
  margin-top: ${({ theme }) => theme.space.s};
  grid-column: 1 / -1;
`;
