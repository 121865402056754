import styled from '@emotion/styled';
import Badge from '@lafourchette/react-chili/dist/cjs/components/Common/Badge';
import { ComponentProps } from 'react';

export const BestOfferLabel = styled((props: ComponentProps<typeof Badge>) => (
  <Badge variant="dark" size="s" structure="square" {...props} />
))`
  background-color: ${({ theme }) => theme.customization.colors.text.default};
  color: ${({ theme }) => theme.customization.colors.text.inverted};
  font-family: ${({ theme }) => theme.customization.fonts.body};
`;
