import { FormattedDateParts, FormattedTime } from 'react-intl';
import { BestOfferLabel } from '@/components/BestOfferLabel/BestOfferLabel';
import * as S from './TimeslotCard.styles';

type TimeSlotCardProps = {
  handleSelectStep: () => void;
  date: Date;
  offerAvailable?: boolean;
  isDate?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
};

export const TimeslotCard = ({
  handleSelectStep,
  date,
  offerAvailable,
  isDate,
  isActive,
  isDisabled,
  dataTestId,
}: TimeSlotCardProps) => {
  return (
    <S.TimeslotOfferWrapper>
      {isDate ? (
        <S.TimeslotDateButton
          data-testid={dataTestId}
          onClick={handleSelectStep}
          isActive={isActive}
          disabled={isDisabled}
        >
          <FormattedDateParts value={date} weekday="short" day="2-digit" month="2-digit">
            {(parts) => (
              <>
                {parts[0].value} <br /> {parts[2].value}/{parts[4].value}
              </>
            )}
          </FormattedDateParts>
        </S.TimeslotDateButton>
      ) : (
        <S.TimeslotButton data-testid={dataTestId} onClick={handleSelectStep} isActive={isActive} disabled={isDisabled}>
          <FormattedTime value={date} />
        </S.TimeslotButton>
      )}
      {offerAvailable && (
        <S.OfferLabelContainer>
          <BestOfferLabel />
        </S.OfferLabelContainer>
      )}
    </S.TimeslotOfferWrapper>
  );
};
